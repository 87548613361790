import './Page.css';
import './AboutUs.css'
import ReserveButton from '../components/ReserveButton';


import React from "react";
import ImageGallery from '../components/ImageGallery';
import NewClient from '../components/NewGuests';

const HomePage: React.FC = () => {

    return (
        <React.Fragment>
            <h3>Your dream hair, untangled!</h3>
            <h1 className="aboutHeader">Welcome!</h1>
            <NewClient/>
            <ReserveButton/>&nbsp;
            <div className="imageContainer">
                <img loading="lazy" src="assets/images/IMG_2725.jpeg" alt="" />
            </div>
            <h2 className="message">Warm Regards From <br></br>The Owner</h2>
            <p>Welcome to Studio D Luxe! I created my salon with a clear vision: to offer clients a safe space where they can truly have a voice. Whether it’s discussing personal matters or expressing their hair goals, I wanted to ensure that everyone who walks through my doors feels heard, valued, and respected. My salon is a judgment-free zone, where individuality is celebrated, and your comfort is our top priority. At Studio D Luxe, we’re not just about providing exceptional hair services—we’re about creating a supportive and welcoming environment where you can relax and be yourself. Whether you’re here for a simple touch-up or a complete transformation, we are dedicated to helping you achieve your best look while making you feel at home. Explore our range of services and let us help you shine, inside and out. We can’t wait to meet you and be a part of your hair journey!</p>
            <p className="best">All The Best,</p>
            <div className="signature">Darlene "D" McLeod</div>
            <ImageGallery location={window.location.pathname}/>
            {/* <footer className="home"></footer> */}
        </React.Fragment>
    )
}

export default HomePage;